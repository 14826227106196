@use 'include-media' as *;
@use '../../../../scss/general/variables' as *;
/* DIALOG STYLES
-------------------------------*/
$dialog-box-width: 360px;
$dialog-box-max-width: 420px;

shared-context-dialog-box {
  /* dialogs are hidden by default */
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  visibility: visible;
  perspective: 1000px;
  z-index: 99;
  background: $color-white;
  box-shadow: 0 2px 6px 0 rgba(#1a0000, 0.1);
  border-radius: 5px;
  border: 1px solid $theme-border-color;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  @include media('>=tablet') {
    right: 52px;
  }

  @include media('<tablet') {
    margin-right: 0.6rem;
  }

  &.dialog-md {
    width: $dialog-box-width;
    padding: 1.5rem 1.4rem;
  }

  &.wide {
    width: $dialog-box-max-width;
  }

  &.right {
    position: relative;
    display: inline-flex;
    right: 0;
    top: 0;
  }

  .app-dialog-box {
    display: block;
    width: 100%;
    max-height: calc(100vh - 168px);

    .dialog-box {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0px;

      &.dialog-sm {
        padding: 0;
      }

      .dialog-header {
        overflow-y: auto;
        max-height: 220px;
      }

      .dialog-body {
        flex: 1;
        margin: 0;
        overflow: hidden;
        outline: 0;

        .app-dialog-body {
          display: flex;
          flex-direction: column;
          max-height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }

      .dialog-footer>div {
        border-top: 1px solid $theme-border-color;
        padding-top: 12px;
        margin-top: 8px;
      }
    }

    .close-icon {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .btn-icon-only {
        padding: 0 4px;
      }
    }

    .close-light-box {
      position: absolute;
      top: -31px;
      right: -20px;
      font-size: 30px;
      color: rgb(255, 255, 255);
      text-shadow: none;
    }

    @include media('<tablet') {
      body {
        font-size: 75%;
      }

      .app-dialog-body {
        padding: 0 0.3em;
      }
    }
  }

  .btn-caret {
    position: absolute;
    margin-left: 0.9em;
    top: -0.7em;
    width: 0;
    height: 0;
    width: 20px;
    height: 20px;

    visibility: hidden;
    opacity: 0;

    border-top: 1px solid $theme-border-color;
    border-right: 1px solid $theme-border-color;
    transform: rotate(-45deg);
    background: white;
    z-index: 1002;

    transition: all 0.6s ease-in;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  @include media('<tablet') {
    width: 90vw;
    max-width: $dialog-box-width;

    &.wide {
      width: 90vw;
      max-width: $dialog-box-max-width;
    }

    .dialog-body {
      padding: 0 0 1em;
    }

    .dialog-footer {
      padding: 1em 0.2em 0.5em;
    }
  }
}

body.app-dialog-open {
  overflow: visible;
}