@use '../../../../scss/general/variables' as *;

.pointee-metric {
  display: flex;
  max-width: 100%;

  .pointee-metric-content {
    display: flex;
    flex-flow: row;
    min-width: 100%;

    .metric-pointer {
      margin-right: 0.5rem;
      margin-top: 5px;
    }

    .metric-main-content {
      display: flex;
      flex-flow: column;
      min-width: 100%;
    }

    .pointee-metric-label {
      display: flex;
      align-items: center;
      overflow: hidden;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        font-weight: 400;
        line-height: 24px;
        color: $theme-font-gray;
      }

      svg-icon {
        margin-left: 4px;
        font-size: 1rem;
      }
    }

    .pointee-metric-value {
      display: flex;
      flex-flow: row;
      align-items: center;

      .metric-percentage-graphic {
        display: flex;
        align-self: center;
      }

      .metric-icon {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }

      .value {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 0;
        max-height: 32px;
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        align-items: baseline;
        overflow: hidden;

        span {
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .value-params {
          color: $theme-font-gray;
          font-size: $theme-font-size;
          font-weight: normal;
          line-height: 14px;
          white-space: nowrap;
        }
      }

      .metric-trend {
        align-self: flex-end;
        padding-bottom: 2px;
      }
    }
  }
}