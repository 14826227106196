@use 'include-media' as *;
@use '../../../../scss/general/variables' as *;
@use '../../../../scss/elements/input' as *;

.custom-input-icon {
  @extend .pointee-input;
  width: 100%;

  input {
    border: none;
  }

  input::placeholder {
    color: $theme-font-placeholder;
  }

  input.input-icon {
    background: $theme-bg-color;
    min-width: 100%;
    padding: 10px 34px 10px 34px;
    border-radius: $theme-border-radius;
    border: 1px solid $theme-border-color;

    &:active,
    &:focus,
    &:hover {
      border: 1px solid $primary-color;
    }
  }

  .custom-icon,
  .clear-icon {
    position: absolute;
    display: flex;
    color: $theme-font-gray;
  }

  .custom-icon {
    cursor: default;
    left: 12px;
  }

  .clear-icon {
    right: 4px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}