@use '../../../../scss/general/variables' as *;

:host {
  position: relative;
  display: block;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.app-modal-header {
  position: relative;
  display: flex;
  padding: 24px 24px 0 24px;

  .modal-header-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;

    h3 {
      line-height: 32px;
      padding-right: 52px;
      word-wrap: anywhere;
    }
  }

  .modal-title-buttons {
    position: absolute;
    display: flex;
    gap: 16px;
    top: 12px;
    right: 12px;
    height: 32px;

    svg-icon {
      color: $theme-font-gray;

      &:hover {
        color: $theme-font-black;
      }
    }
  }
}

.app-modal-body {

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 16px;
  }

  padding: 16px 24px 32px;
  overflow-y: auto;
}

.app-modal-footer {
  background: $theme-title-bg-color;
  padding: 16px 24px;

  button {
    min-width: 64px;
  }
}