:host {
  display: block;
  width: 100%;
}

app-select-check-all .mat-mdc-checkbox {
  cursor: pointer;
  padding: 9px 12px;
  min-height: unset;
  line-height: 22px;

  label {
    width: 100% !important;
  }
}